html, body, .gandalf-container, #root {
    height: 100%;
}

body {
    margin: 0;
    position: relative;
}

.blue {
    background-color: rgb(0, 89, 179);
}

.yellow {
    background-color: rgb(255, 214, 56);
}

.black {
    background-color: black;
}

.white {
    background-color: white;
}

.height-50 {
    height: 50%;
}

.gandalf-image {
    position: absolute;
    margin: auto;
    width: 65%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.button-5 {
    align-items: center;
    background-clip: padding-box;
    background-color: white;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: black;
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    min-height: 3rem;
    padding: calc(.875rem - 1px) calc(1.5rem - 1px);
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    width: auto;
}

.button-5:hover,
.button-5:focus {
    background-color: #959595;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
    transform: translateY(-1px);
}

.button-5:active {
    background-color: #959595;
    box-shadow: rgba(0, 0, 0, .06) 0 2px 4px;
    transform: translateY(0);
}